import { Routes } from "@angular/router";

export const content: Routes = [
  {
    path: "",
    loadChildren: () =>
      import("../../content/content.module").then((m) => m.ContentModule),
  },
  // {
  //   path: "widgets",
  //   loadChildren: () =>
  //     import("../../components/widgets/widgets.module").then(
  //       (m) => m.WidgetsModule
  //     ),
  // },
  // {
  //   path: "buttons",
  //   loadChildren: () =>
  //     import("../../components/buttons/buttons.module").then(
  //       (m) => m.ButtonsModule
  //     ),
  // },
  // {
  //   path: "chart",
  //   loadChildren: () =>
  //     import("../../components/charts/charts.module").then(
  //       (m) => m.ChartModule
  //     ),
  // },
];
