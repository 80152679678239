import { environment } from "./../../environments/environment";
import { Injectable } from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";
import { Observable } from "rxjs";
import { ApprovalRequestModel } from "../models/approval-request.model";
import { SubmitListModel } from "../models/request-children-models/submit.model";
import { VisitType } from "src/types/types";

@Injectable()
export class RequestsService {
  private REQUESTS_ENDPOINTS = {
    approvalRequest: "/approval-request",
  };

  constructor(private _http: HttpClient) {}

  public getPendingApprovalRequestsList(): Observable<{
    data: Array<ApprovalRequestModel>;
  }> {
    return this._http.get<{ data: Array<ApprovalRequestModel> }>(
      `${environment.CURRENT_DOMAIN}${this.REQUESTS_ENDPOINTS.approvalRequest}/pending`
    );
  }

  public getProcessedApprovalRequestsList(): Observable<{
    data: Array<ApprovalRequestModel>;
  }> {
    return this._http.get<{ data: Array<ApprovalRequestModel> }>(
      `${environment.CURRENT_DOMAIN}${this.REQUESTS_ENDPOINTS.approvalRequest}/processed`
    );
  }

  public getSingleRequest(
    id: number
  ): Observable<{ data: ApprovalRequestModel }> {
    return this._http.get<{ data: ApprovalRequestModel }>(
      `${environment.CURRENT_DOMAIN}${this.REQUESTS_ENDPOINTS.approvalRequest}/${id}`
    );
  }

  public submitRequest(
    id: number,
    submittedList: Array<SubmitListModel>,
    rejectionReason: string | null,
    reviewerId: number
  ): Observable<any> {
    const body: {
      items: Array<SubmitListModel>;
      rejectionReason: string | null;
      reviewer_id: number;
    } = {
      items: submittedList,
      rejectionReason: rejectionReason,
      reviewer_id: reviewerId,
    };

    return this._http.patch<any>(
      `${environment.CURRENT_DOMAIN}${this.REQUESTS_ENDPOINTS.approvalRequest}/${id}`,
      body
    );
  }

  public createNewRequest(
    emergency: number,
    parentId: number | null,
    followUpReason: string | null,
    patientId: number,
    creatorId: number,
    diagnosesArray: Array<{ code: string; name: string }>,
    diagnosisDescription: string,
    symptomsArray: Array<{ code: string; name: string }>,
    symptomsDescription: string,
    itemsArray: Array<{
      service: { code: string; name: string };
      quantity: number;
      cost: number;
    }>,
    lowBloodPressure: number | null,
    highBloodPressure: number | null,
    pulse: number | null,
    rr: number | null,
    temperature: number | null,
    weight: number | null,
    height: number | null,
    duration: number | null,
    visitType: VisitType
  ): Observable<any> {
    let items: Array<{ name: string; quantity: number; cost: number }> = [];
    itemsArray.map((item) => {
      items.push({
        name: item.service.name,
        quantity: item.quantity,
        cost: item.cost,
      });
    });

    const body = {
      emergency_case: emergency,
      parent_request_id: parentId,
      followUpReason: followUpReason,
      patient_id: patientId,
      creator_id: creatorId,
      diagnosis_description: diagnosisDescription,
      diagnoses: diagnosesArray,
      symptomsObj: {
        description: symptomsDescription,
        symptoms: symptomsArray,
      },
      items: items,
      patient_measurments: {
        low_blood_pressure: lowBloodPressure,
        high_blood_pressure: highBloodPressure,
        pulse: pulse,
        respiratory_rate: rr,
        temperature: temperature,
        weight: weight,
        height: height,
        illness_duration: duration,
      },
      visit_type: parentId ? "FollowUp" : visitType,
    };

    return this._http.post(
      `${environment.CURRENT_DOMAIN}${this.REQUESTS_ENDPOINTS.approvalRequest}`,
      body
    );
  }
}
