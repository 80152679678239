import { full } from "./shared/routes/full.routes";
import { content } from "./shared/routes/routes";
import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { LoginComponent } from "./auth/login/login.component";

import { AdminGuard } from "./auth/guard/admin.guard";
import { ContentComponent } from "./shared/components/layout/content/content.component";
import { FullComponent } from "./shared/components/layout/full/full.component";

const routes: Routes = [
  // {
  //   path: "",
  //   pathMatch: "full",
  //   redirectTo: "",
  // },
  {
    path: "auth/login",
    component: LoginComponent,
  },
  // {
  //   path: "najeb-ai",
  //   canActivate: [AdminGuard],
  //   loadChildren: () =>
  //     import("./content/content.module").then((module) => module.ContentModule),
  // },
  {
    path: "",
    component: ContentComponent,
    canActivate: [AdminGuard],
    children: content,
  },
  // {
  //   path: "",
  //   component: FullComponent,
  //   canActivate: [AdminGuard],
  //   children: full,
  // },
  {
    path: "**",
    pathMatch: "full",
    redirectTo: "",
  },
];

@NgModule({
  imports: [
    [
      RouterModule.forRoot(routes, {
        anchorScrolling: "enabled",
        scrollPositionRestoration: "enabled",
      }),
    ],
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
