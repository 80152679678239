// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

const DOMAIN = {
  ServerDev: "https://dev.api.najeb.ai",
  ServerStage: "https://dss.api.najeb.ai",
  ServerProduction: "",
  Local: "",
};

export const environment = {
  production: false,
  googleMapKey: "",
  jwt_whitelist: ["dss.api.najeb.ai", "dev.api.najeb.ai"],
  CURRENT_DOMAIN: DOMAIN.ServerDev,
};
