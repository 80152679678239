import { AuthResponseModel } from "./../../models/auth-models/auth-response.model";
import { AuthService } from "./../services/auth.service";
import { Component, OnInit } from "@angular/core";
import { FormBuilder, Validators, FormGroup } from "@angular/forms";
import { Router } from "@angular/router";
import { take, tap } from "rxjs";
import { AccountModel } from "src/app/models/auth-models/account.model";

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.scss"],
})
export class LoginComponent implements OnInit {
  newUser = false;
  loginForm: FormGroup;
  show: boolean = false;
  errorMessage: any;

  constructor(
    private _fb: FormBuilder,
    private _router: Router,
    private _authService: AuthService
  ) {
    this.loginForm = this._fb.group({
      email: ["", [Validators.required, Validators.email]],
      password: ["", Validators.required],
    });
  }

  ngOnInit() {}

  public login() {
    const userInputs: AccountModel = {
      email: this.loginForm.value["email"],
      password: this.loginForm.value["password"],
    };
    // localStorage.setItem("user", JSON.stringify(userInputs));
    // this._router.navigate(["requests", "processed-requests"]);
    this._authService
      .login(userInputs)
      .pipe(
        tap((user: AuthResponseModel) => {
          localStorage.setItem("user", JSON.stringify(user));
          switch (user.role) {
            case "Reviewer":
              this._router.navigate(["processed-requests"]);
              break;
            case "MedicalProvider":
              this._router.navigate(["create-request"]);
              break;
            case "Supervisor":
              this._router.navigate(["supervisor-dashboard"]);
              break;
            default:
              this._router.navigate(["insurance-admin-dashboard"]);
          }
        }),
        take(1)
      )
      .subscribe();
  }

  public showPassword() {
    this.show = !this.show;
  }
}
