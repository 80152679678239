import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { AccountModel } from "../../models/auth-models/account.model";
import { environment } from "../../../environments/environment";
import { AuthResponseModel } from "src/app/models/auth-models/auth-response.model";

@Injectable({
  providedIn: "root",
})
export class AuthService {
  private AUTH_ENDPOINTS = {
    adminLogin: "/auth/login",
  };

  constructor(private _http: HttpClient) {}

  public login(account: AccountModel): Observable<AuthResponseModel> {
    const adminAccountBody: AccountModel = {
      email: account.email,
      password: account.password,
    };

    return this._http.post<AuthResponseModel>(
      environment.CURRENT_DOMAIN + this.AUTH_ENDPOINTS.adminLogin,
      adminAccountBody
    );
  }
}
